import "./OrganizationRequests.scss";

import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FiCheck, FiTrash2 } from "react-icons/fi";

import Button from "../../../components/Utils/Button/Button";
import DynamicTable from "../../../components/Utils/DynamicTable/DynamicTable";
import { StyledInputText } from "../../../components/Utils/StyledInput/StyledInput";
import OrganizationRequestType from "../../../types/OrganizationRequest";
import appFetch from "../../../Utils/Services/Fetch/appFetch";

const OrganizationRequests = () => {
  const [demandes, setDemandes] = useState<OrganizationRequestType[]>([]);
  const [error, setError] = useState<string>("");
  const [search, setSearch] = useState("");

  const filteredRequests = demandes?.filter((demande) => {
    return (
      demande.user?.name?.toLowerCase().includes(search.toLowerCase()) ||
      demande.status.toString().includes(search.toLowerCase())
    );
  });

  const fetchData = async () => {
    const data = await appFetch(`/api/organization/requests/list`, {
      method: "GET",
    });
    if (data) {
      if (data.message === "Pas de demande en attente") {
        setError(data.message);
        return;
      }
      setDemandes(data.requests);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const rejectsRequest = async (requestId: number) => {
    const response = await appFetch(
      `/api/organization/requests/delete?request_id=${requestId}`,
      {
        method: "DELETE",
      },
    );
    if (response) {
      toast.success(response.message);
      setDemandes(
        demandes.filter((demande) => demande.request_id !== requestId),
      );
    }
  };

  const validateRequest = async (requestId: number) => {
    const response = await appFetch(
      `/api/organization/requests/validate?request_id=${requestId}`,
      {
        method: "PUT",
      },
    );
    if (response && response.error) {
      toast.error(response.message);
    } else {
      toast.success("Demande validée");
      setDemandes(
        demandes.filter((demande) => demande.request_id !== requestId),
      );
    }
  };

  const requestColumns = [
    {
      title: "Nom",
      dataIndex: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Date",
      dataIndex: "created_at",
      render: (created_at: string) => new Date(created_at).toLocaleDateString(),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (_: any, request: OrganizationRequestType) => (
        <>
          <Button
            className="validateButton"
            onClick={() => validateRequest(request.request_id)}
            icon={<FiCheck />}
            style="square"
          />
          <Button
            className="deleteButton"
            onClick={() => rejectsRequest(request.request_id)}
            icon={<FiTrash2 />}
            style="square"
          />
        </>
      ),
    },
  ];

  return (
    <div className="demandesListPage">
      <StyledInputText
        label="Rechercher"
        value={search}
        setValue={setSearch}
        className="searchInput"
      />
      <DynamicTable
        data={filteredRequests}
        columns={requestColumns}
        pagined
        maxRow={10}
      />
      {error && <div className="demandesItem">{error}</div>}
    </div>
  );
};

export default OrganizationRequests;
