import "./ServiceCard.scss";

import { useState } from "react";
import toast from "react-hot-toast";
import { FiEdit2 } from "react-icons/fi";
import { IoAddOutline } from "react-icons/io5";

interface ServiceCardProps {
  service: ServiceType;
  setCatalogue: React.Dispatch<React.SetStateAction<CatalogueItemType[]>>;
}

import Button from "../../../../components/Utils/Button/Button";
import Modal from "../../../../components/Utils/Modal/Modal";
import useLoadingStore from "../../../../stores/LoadingStore";
import ServiceType from "../../../../types/Service";
import appFetch from "../../../../Utils/Services/Fetch/appFetch";
import { CatalogueItemType } from "../CatalogueList";
import ModalContent, { ModalFooterButton } from "./ModalContent/ModalContent";

const ServiceCard: React.FC<ServiceCardProps> = ({ service, setCatalogue }) => {
  const [updatedService, setUpdatedService] = useState<ServiceType>(service);
  const { setLoading } = useLoadingStore();

  const isAddService = service.service_id === -1;

  const addService = async () => {
    setLoading("addService", true);
    const formData = new FormData();
    formData.append("name", updatedService.name);
    formData.append("price", updatedService.price.toString());
    formData.append("description", updatedService.description);
    // formData.append("duration", updatedService.duration);
    formData.forEach((value, key) => {
      console.log(key, value);
    });

    try {
      const response = await appFetch("/api/services/addService", {
        method: "POST",
        body: formData,
      });
      if (response) {
        refreshData();
        handleReset();
        toast.success(response.message);
      }
    } catch (error) {
      console.error("Erreur lors de l'ajout du service:", error);
      toast.error("Erreur lors de l'ajout du service");
    }
    setLoading("addService", false);
  };

  const updateService = async (serviceId: number) => {
    setLoading("updateService", true);
    const formData = new FormData();
    formData.append("name", updatedService.name);
    console.log(updatedService.name);
    console.log(formData);
    formData.append("price", updatedService.price.toString());
    formData.append("description", updatedService.description);
    // formData.append("duration", updatedService.duration);
    formData.append("serviceId", serviceId.toString());
    console.log(formData);

    try {
      const response = await appFetch("/api/services/updateService", {
        method: "POST",
        body: formData,
      });
      if (response) {
        refreshData();
        toast.success(response.message);
      }
    } catch (error) {
      console.error("Erreur lors de l'ajout du service:", error);
      toast.error("Erreur lors de l'ajout du service");
    }
    setLoading("updateService", false);
  };

  const refreshData = async () => {
    const data = await appFetch(`/api/services/list`);
    if (data) {
      const emptyItem = {
        service_id: -1,
        name: "",
        price: 0,
        description: "",
        duration: "",
      };

      // Ajout de l'élément vide au début de la liste
      setCatalogue([emptyItem, ...data]);
    }
  };

  const updateOrAddService = async (serviceId?: number) => {
    if (isAddService) {
      // Ajout d'un service
      await addService();
      refreshData();
    } else {
      if (!serviceId) return;
      await updateService(serviceId);
      refreshData();
    }
  };

  const deleteService = async () => {
    setLoading("deleteService", true);
    try {
      const response = await appFetch(
        `/api/services/removeService?service_id=${service.service_id}`,
        {
          method: "DELETE",
        },
      );
      if (response) {
        refreshData();
        toast.success(response.message);
      }
    } catch (error) {
      console.error("Erreur lors de la suppression du service :", error);
      toast.error("Erreur lors de la suppression du service");
    }
    setLoading("deleteService", false);
  };

  const handleReset = () => {
    setUpdatedService({
      service_id: -1,
      name: "",
      price: "0",
      description: "",
      duration: "",
      updated_at: "",
      created_at: "",
      tva_rate: 0,
      discount: 0,
    });
  };

  return (
    <div className="serviceItem" key={service.service_id}>
      {isAddService ? (
        <Modal
          modalTitle="Ajouter un service"
          modalContent={
            <ModalContent
              setUpdatedService={setUpdatedService}
              updatedService={updatedService}
            />
          }
          modalButton={({ onClick }) => (
            <Button
              icon={<IoAddOutline size={40} />}
              onClick={onClick}
              style="transparent"
              width={"100%"}
              height={"100%"}
            />
          )}
          modalFooterButton={
            <ModalFooterButton onClick={updateOrAddService} addService={true} />
          }
          modalFooterClass="buttonLogout"
        />
      ) : (
        <>
          <div className="serviceItemDetails">
            <div className="serviceItemName">{service.name}</div>
            <div className="serviceItemDescription">
              <span>Description:</span> {service.description}
            </div>
            <div className="serviceItemPrice">
              <span>Prix :</span> {service.price}
            </div>
            {/* <div className="serviceItemDuration">
              <span>Durée:</span> {service.duration}h
            </div> */}
          </div>
          <Modal
            modalTitle="Modifier le service"
            modalContent={
              <ModalContent
                setUpdatedService={setUpdatedService}
                updatedService={updatedService}
              />
            }
            modalButton={({ onClick }) => (
              <Button
                icon={<FiEdit2 />}
                onClick={onClick}
                style="square"
                className="modalButton"
                width={30}
                height={30}
              />
            )}
            modalFooterButton={
              <ModalFooterButton
                onClickWithParam={updateOrAddService}
                paramValue={service.service_id}
                onDelete={deleteService}
              />
            }
            modalFooterClass="buttonLogout"
            className="modalEditService"
          />
        </>
      )}
    </div>
  );
};

export default ServiceCard;
