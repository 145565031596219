import "react-datepicker/dist/react-datepicker.css";
import "./Dashboard.scss";

import EventRevenues from "./EventRevenues/EventRevenues";
import ExpensesVsRevenuesChart from "./ExpensesVsRevenuesChart/ExpensesVsRevenuesChart";
import ProductRentalStats from "./ProductRentalStats/ProductRentalStats";
import RevenuesAndExpensesOverTimeChart from "./RevenuesAndExpensesOverTimeChart/RevenuesAndExpensesOverTimeChart";

const Dashboard = () => {
  return (
    <div className={`dashboardPage`}>
      <div className="dashboardPageDiv">
        <ExpensesVsRevenuesChart />
        <EventRevenues />
        <RevenuesAndExpensesOverTimeChart />
        <ProductRentalStats />
      </div>
    </div>
  );
};

export default Dashboard;
