import "./Catalogue.scss";

import React, { useState } from "react";

import HeaderButtons from "../../components/Utils/HeaderButtons/HeaderButtons";
import { CallType } from "../../stores/CatalogueStore";
import CatalogueList from "./CatalogueList/CatalogueList";
import Category from "./Category/Category";

const Catalogue: React.FC = () => {
  const [activeButton, setActiveButton] = useState<CallType>("Produits");

  const buttons = [
    { id: "Produits", render: "Produits" },
    { id: "Services", render: "Services" },
    { id: "Catégories", render: "Catégories" },
  ];

  return (
    <div className="root">
      <HeaderButtons
        activeButton={activeButton}
        setActiveButton={setActiveButton as any}
        buttons={buttons}
      />
      <div className="cataloguePage">
        {activeButton === "Catégories" ? (
          <Category />
        ) : (
          <CatalogueList type={activeButton} />
        )}
      </div>
    </div>
  );
};
export default Catalogue;
