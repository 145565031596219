import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { BiDownload } from "react-icons/bi";
import { FiEdit2 } from "react-icons/fi";

import DeleteButton from "../../../../components/DeleteButton/DeleteButton";
import Button from "../../../../components/Utils/Button/Button";
import DynamicTable from "../../../../components/Utils/DynamicTable/DynamicTable";
import Spinner from "../../../../components/Utils/Spinner/Spinner";
import useLoadingStore from "../../../../stores/LoadingStore";
import AccountingExpenseType from "../../../../types/AccountingExpense";
import appFetch from "../../../../Utils/Services/Fetch/appFetch";

const ExpenseList: React.FC<{
  onEdit: (expense: AccountingExpenseType) => void;
  onEditPage?: boolean;
  search: string;
  startDate: Date;
  endDate: Date;
}> = ({ onEdit, onEditPage, search, startDate, endDate }) => {
  const [expense, setExpense] = useState<AccountingExpenseType[]>();
  const { loadingStates, setLoading } = useLoadingStore();
  const [sortState, setSortState] = useState<{
    column: string;
    direction: "asc" | "desc";
  } | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading("getExpenses", true);
      const expensesData = await appFetch("/api/accounting/getExpenses", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          startDate: startDate?.toISOString(),
          endDate: endDate?.toISOString(),
        }),
      });
      if (expensesData) {
        setExpense(expensesData.expenses);
      }
      setLoading("getExpenses", false);
    };

    fetchData();
  }, [endDate, startDate]);

  const handleSort = (column: keyof AccountingExpenseType | "event.name") => {
    const direction = sortState?.direction === "asc" ? "desc" : "asc";
    setSortState({ column, direction });
    setExpense((prevExpenses) => {
      if (!prevExpenses) return prevExpenses;
      return prevExpenses.slice().sort((a, b) => {
        let aValue: any;
        let bValue: any;
        if (column === "event.name") {
          aValue = a.event?.name;
          bValue = b.event?.name;
        } else if (column === "price") {
          aValue = Number(a.price);
          bValue = Number(b.price);
        } else {
          aValue = a[column];
          bValue = b[column];
        }
        if (aValue == null || bValue == null) return 0;
        if (aValue < bValue) return direction === "asc" ? -1 : 1;
        if (aValue > bValue) return direction === "asc" ? 1 : -1;
        return 0;
      });
    });
  };

  const filteredExpenses = expense?.filter((expense) => {
    return (
      expense.product_name.toLowerCase().includes(search.toLowerCase()) ||
      expense.user_name?.toLowerCase().includes(search.toLowerCase()) ||
      expense.event?.name?.toLowerCase().includes(search.toLowerCase())
    );
  });

  const downloadExpense = async (expense: AccountingExpenseType) => {
    setLoading(`downloadExpense${expense.expense_id}`, true);
    try {
      const fileUrl = `/api/accounting/getExpensesFile?expense_id=${expense.expense_id}`;
      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = expense.product_name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      toast.error("Erreur lors du téléchargement du fichier");
    }
    setLoading(`downloadExpense${expense.expense_id}`, false);
  };

  const deleteExpense = async (expenseId: number) => {
    setLoading(`deleteExpense${expenseId}`, true);
    const response = await appFetch(
      `/api/accounting/deleteExpense?expense_id=${expenseId}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ expense_id: expenseId }),
      },
    );

    if (response && response.error) {
      toast.error(response.message);
    } else if (response && response.message) {
      toast.success(response.message);

      setExpense((prevExpenses) => {
        return prevExpenses?.filter(
          (expense) => expense.expense_id !== expenseId,
        );
      });
    }
    setLoading(`deleteExpense${expenseId}`, false);
  };

  let columns = [
    {
      title: "Nom",
      dataIndex: "product_name",
      sortable: true,
      onSort: () => handleSort("product_name"),
      sortableClass:
        sortState?.column === "product_name" ? sortState.direction : undefined,
    },
    {
      title: "Acheteur",
      dataIndex: "user_name",
      render: (_user_name: string, expense: AccountingExpenseType) => {
        return expense.user_name ? expense.user_name : "Organisation";
      },
      sortable: true,
      onSort: () => handleSort("user_name"),
      sortableClass:
        sortState?.column === "user_name" ? sortState.direction : undefined,
    },
    {
      title: "Date",
      dataIndex: "recorded_date",
      render: (value: string) => {
        return new Date(value).toLocaleDateString("fr-FR", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
        });
      },
      sortable: true,
      onSort: () => handleSort("recorded_date"),
      sortableClass:
        sortState?.column === "recorded_date" ? sortState.direction : undefined,
    },
    {
      title: "Mode de paiement",
      dataIndex: "payment_method",
      sortable: true,
      onSort: () => handleSort("payment_method"),
      sortableClass:
        sortState?.column === "payment_method"
          ? sortState.direction
          : undefined,
    },
    {
      title: "Event",
      dataIndex: "event.name",
      render: (_event: string, expense: AccountingExpenseType) => {
        return expense.event?.name ?? "N/A";
      },
      sortable: true,
      onSort: () => handleSort("event.name"),
      sortableClass:
        sortState?.column === "event.name" ? sortState.direction : undefined,
    },
    {
      title: "Prix",
      dataIndex: "price",
      sortable: true,
      onSort: () => handleSort("price"),
      sortableClass:
        sortState?.column === "price" ? sortState.direction : undefined,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (_: string, record: AccountingExpenseType) => (
        <div style={{ display: "flex", gap: "10px" }}>
          <Button
            className="downloadButton"
            onClick={() => downloadExpense(record)}
            icon={<BiDownload />}
            isLoading={loadingStates[`downloadExpense${record.expense_id}`]}
            style="square"
          />
          <Button
            className="editButton"
            onClick={() => onEdit(record)}
            icon={<FiEdit2 />}
            style="square"
          />

          <DeleteButton
            onClick={() => deleteExpense(record.expense_id)}
            isLoading={loadingStates[`deleteExpense${record.expense_id}`]}
          />
        </div>
      ),
    },
  ];

  if (onEditPage) {
    columns = columns.filter((column) => column.title !== "Actions");
  }

  const handleRowClick = (expense: AccountingExpenseType) => {
    if (onEditPage) {
      onEdit(expense);
    }
  };

  return (
    <div className={`accountingListPage ${onEditPage ? "onEditPage" : ""}`}>
      <div className="tableContainer">
        {loadingStates.getExpenses ? (
          <Spinner />
        ) : (
          <DynamicTable
            data={filteredExpenses ?? []}
            columns={columns}
            onClick={handleRowClick}
            pagined
            maxRow={25}
            mobile
          />
        )}
      </div>
    </div>
  );
};

export default ExpenseList;
