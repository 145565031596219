import "./SaleList.scss";

import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { BiDownload } from "react-icons/bi";
import { FaFileInvoiceDollar } from "react-icons/fa";
import { FiEdit2 } from "react-icons/fi";

import DateFilter from "../../../components/DateFilter/DateFilter";
import DeleteButton from "../../../components/DeleteButton/DeleteButton";
import Button from "../../../components/Utils/Button/Button";
import DynamicTable from "../../../components/Utils/DynamicTable/DynamicTable";
import { StyledInputText } from "../../../components/Utils/StyledInput/StyledInput";
import useLoadingStore from "../../../stores/LoadingStore";
import SaleType from "../../../types/Sale";
import appFetch from "../../../Utils/Services/Fetch/appFetch";

const SaleList: React.FC<{
  onEdit: (sale: SaleType) => void;
  onEditPage?: boolean;
}> = ({ onEdit, onEditPage }) => {
  const [sale, setSale] = useState<SaleType[]>();
  const { loadingStates, setLoading } = useLoadingStore();
  const [search, setSearch] = useState("");

  const startOfYear = new Date();
  startOfYear.setMonth(0, 1);

  const endOfMonth = new Date();
  endOfMonth.setMonth(endOfMonth.getMonth() + 1, 0);

  const [dateRange, setDateRange] = useState<[Date, Date]>([
    startOfYear,
    endOfMonth,
  ]);
  const [startDate, endDate] = dateRange;

  useEffect(() => {
    const fetchData = async () => {
      const salesData = await appFetch("/api/sale/getSales", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          startDate: startDate?.toISOString(),
          endDate: endDate?.toISOString(),
        }),
      });
      if (salesData) {
        setSale(salesData.sales);
      }
    };

    fetchData();
  }, [endDate, startDate]);

  const filteredSales = sale?.filter((sale) => {
    return (
      sale.name.toLowerCase().includes(search.toLowerCase()) ||
      sale.client?.client_name?.toLowerCase().includes(search.toLowerCase())
    );
  });

  const downloadSale = async (sale: SaleType) => {
    setLoading(`downloadSale${sale.sale_id}`, true);
    console.log(sale);
    try {
      const response = await appFetch(
        `/api/sale/getSalePdf?sale_id=${sale.sale_id}`,
      );
      console.log(response);
      if (response && response instanceof Blob) {
        const filename = `DEVIS-${sale.formatted_sale_id}.pdf`;

        const blobURL = URL.createObjectURL(response);
        const link = document.createElement("a");
        link.href = blobURL;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else if (response && response.error) {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error("Erreur lors du téléchargement du fichier");
    }
    setLoading(`downloadSale${sale.sale_id}`, false);
  };

  const deleteSale = async (saleId: number) => {
    setLoading(`deleteSale${saleId}`, true);
    const response = await appFetch(`/api/sale/deleteSale?sale_id=${saleId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ sale_id: saleId }),
    });
    if (response && response.error) {
      toast.error(response.message);
    } else if (response && response.message) {
      document.getElementById("cancelModalButton")?.click();
      toast.success(response.message);
      setSale((prevSales) =>
        prevSales?.filter((sale) => sale.sale_id !== saleId),
      );
    }
    setLoading(`deleteSale${saleId}`, false);
  };

  const createInvoice = async (saleId: number) => {
    setLoading(`createInvoice${saleId}`, true);
    const response = await appFetch(
      `/api/invoice/createInvoice?sale_id=${saleId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      },
    );

    if (response && response.error) {
      toast.error(response.message);
    } else if (response && response.message) {
      toast.success(response.message);
    }
    setLoading(`createInvoice${saleId}`, false);
  };

  let columns = [
    {
      title: "Nom",
      dataIndex: "name",
    },
    {
      title: "Date",
      dataIndex: "recorded_date",
      render: (value: string) => {
        return new Date(value).toLocaleDateString("fr-FR", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
        });
      },
    },

    {
      title: "Prix total",
      dataIndex: "total_price",
      render: (value: number) => {
        return `${value} €`;
      },
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (_: string, record: SaleType) => (
        <div style={{ display: "flex", gap: "10px" }}>
          <Button
            className="downloadButton"
            onClick={() => downloadSale(record)}
            icon={<BiDownload />}
            isLoading={loadingStates[`downloadSale${record.sale_id}`]}
            style="square"
          />
          <Button
            className="editButton"
            onClick={() => onEdit(record)}
            icon={<FiEdit2 />}
            style="square"
          />
          {!record.invoice_id && (
            <DeleteButton
              onClick={() => deleteSale(record.sale_id)}
              isLoading={loadingStates[`deleteSale${record.sale_id}`]}
            />
          )}
          <Button
            className="createInvoiceButton"
            onClick={() => createInvoice(record.sale_id)}
            icon={<FaFileInvoiceDollar />}
            isLoading={loadingStates[`createInvoice${record.sale_id}`]}
            style="square"
          />
        </div>
      ),
    },
  ];
  if (onEditPage) {
    columns = columns.filter((column) => column.title !== "Actions");
  }

  console.log(filteredSales);

  const handleRowClick = (sale: SaleType) => {
    if (onEditPage) {
      onEdit(sale);
    }
  };

  return (
    <div className={`saleListPage ${onEditPage ? "onEditPage" : ""}`}>
      <span className="listHeader">
        <StyledInputText
          label="Rechercher"
          value={search}
          setValue={setSearch}
          className="searchInput"
        />
        <DateFilter
          startDate={startDate}
          endDate={endDate}
          setDateRange={setDateRange}
        />
      </span>
      <div className="tableContainer">
        <DynamicTable
          data={filteredSales ?? []}
          columns={columns}
          onClick={handleRowClick}
          pagined
          maxRow={25}
          mobile
        />
      </div>
    </div>
  );
};

export default SaleList;
