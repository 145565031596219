import "./ProductCard.scss";

import { useState } from "react";
import toast from "react-hot-toast";
import { FiEdit2 } from "react-icons/fi";
import { IoAddOutline } from "react-icons/io5";

import Button from "../../../../components/Utils/Button/Button";
import Modal from "../../../../components/Utils/Modal/Modal";
import useLoadingStore from "../../../../stores/LoadingStore";
import { CategoryType, SubCategoryType } from "../../../../types/Category";
import ProductType from "../../../../types/Product";
import appFetch from "../../../../Utils/Services/Fetch/appFetch";
import { CatalogueItemType } from "../CatalogueList";
import ModalContent, { ModalFooterButton } from "./ModalContent/ModalContent";
interface ProductCardProps {
  product: ProductType;
  setCatalogue: React.Dispatch<React.SetStateAction<CatalogueItemType[]>>;
  categories: CategoryType[];
  subCategories: SubCategoryType[];
}
const ProductCard: React.FC<ProductCardProps> = ({
  product,
  setCatalogue,
  categories,
  subCategories,
}) => {
  const [updatedProduct, setUpdatedProduct] = useState<ProductType>(product);
  const { setLoading } = useLoadingStore();

  const isAddProduct = product.product_id === -1;

  const addProduct = async () => {
    setLoading("addProduct", true);
    const formData = new FormData();
    formData.append("name", updatedProduct.name);
    formData.append("price", updatedProduct.price.toString());
    formData.append("category_id", updatedProduct.category_id.toString());
    if (updatedProduct.subCategory_id) {
      formData.append(
        "subCategory_id",
        updatedProduct.subCategory_id.toString(),
      );
    }
    formData.append("stock", updatedProduct.stock.toString());
    if (updatedProduct.image instanceof File) {
      formData.append("file", updatedProduct.image, updatedProduct.image.name);
    }

    try {
      const response = await appFetch("/api/products/addProduct", {
        method: "POST",
        body: formData,
      });
      if (response) {
        refreshData();
        handleReset();

        toast.success(response.message);
      }
    } catch (error) {
      console.error("Erreur lors de l'ajout du produit:", error);
      toast.error("Erreur lors de l'ajout du produit");
    }
    setLoading("addProduct", false);
  };

  const updateProduct = async (productId: number) => {
    setLoading("updateProduct", true);
    const formData = new FormData();
    formData.append("name", updatedProduct.name);
    formData.append("price", updatedProduct.price.toString());
    formData.append("category_id", updatedProduct.category_id.toString());
    formData.append("productId", productId.toString());
    if (updatedProduct.subCategory_id) {
      formData.append(
        "subCategory_id",
        updatedProduct.subCategory_id.toString(),
      );
    }
    formData.append("stock", updatedProduct.stock.toString());
    if (updatedProduct.image instanceof File) {
      formData.append("file", updatedProduct.image, updatedProduct.image.name);
    }

    try {
      const response = await appFetch("/api/products/updateProduct", {
        method: "POST",
        body: formData,
      });
      if (response) {
        refreshData();
        toast.success(response.message);
      }
    } catch (error) {
      console.error("Erreur lors de l'ajout du produit:", error);
      toast.error("Erreur lors de l'ajout du produit");
    }
    setLoading("updateProduct", false);
  };

  const refreshData = async () => {
    const data = await appFetch(`/api/products/list`);
    if (data) {
      const emptyItem = {
        category_id: "",
        created_at: "",
        image: "",
        name: "",
        price: "",
        product_id: -1, // id fictif
        stock: 0,
        subCategory_id: null,
        updated_at: "",
      };

      // Ajout de l'élément vide au début de la liste
      setCatalogue([emptyItem, ...data]);
    }
  };

  const updateOrAddProduct = async (productId?: number) => {
    if (isAddProduct) {
      // Ajout d'un produit
      await addProduct();
      refreshData();
    } else {
      if (!productId) return;
      document.getElementById("cancelModalButton")?.click();

      await updateProduct(productId);
      refreshData();
    }
  };

  const deleteProduct = async () => {
    setLoading("deleteProduct", true);
    try {
      const response = await appFetch(
        `/api/products/removeProduct?product_id=${product.product_id}`,
        {
          method: "DELETE",
        },
      );
      if (response) {
        refreshData();
        document.getElementById("cancelModalButton")?.click();
        toast.success(response.message);
      }
    } catch (error) {
      console.error("Erreur lors de la suppression du produit :", error);
      toast.error("Erreur lors de la suppression du produit");
    }
    setLoading("deleteProduct", false);
  };

  const handleReset = () => {
    setUpdatedProduct({
      category_id: -1,
      created_at: "",
      image: null,
      name: "",
      price: "",
      product_id: -1,
      stock: 0,
      subCategory_id: null,
      updated_at: "",
      tva_rate: 0,
      discount: 0,
    });
  };

  return (
    <div className="catalogueItem" key={product.product_id}>
      {isAddProduct ? (
        <Modal
          modalTitle="Ajouter un produit"
          modalContent={
            <ModalContent
              setUpdatedProduct={setUpdatedProduct}
              updatedProduct={updatedProduct}
              categories={categories || []}
              subCategories={subCategories || []}
            />
          }
          modalButton={({ onClick }) => (
            <Button
              icon={<IoAddOutline size={40} />}
              onClick={onClick}
              style="transparent"
              width={"100%"}
              height={"100%"}
            />
          )}
          modalFooterButton={
            <ModalFooterButton
              onClick={updateOrAddProduct}
              addProduct={true}
              handleReset={handleReset}
            />
          }
          modalFooterClass="buttonLogout"
        />
      ) : (
        <>
          <img
            src={product.image as string}
            alt={product.name}
            className="catalogueItemImage"
          />
          <div className="catalogueItemDetails">
            <div className="catalogueItemName">{product.name}</div>
            <div className="catalogueItemPrice">
              <span>Prix :</span> {product.price}
            </div>
            <div className="catalogueItemCategory">
              <span>Catégorie:</span>{" "}
              {
                categories?.find(
                  (cat) => cat.category_id === product.category_id,
                )?.name
              }
            </div>
            {product.subCategory_id && (
              <div className="catalogueItemSubcategory">
                <span>Sous-catégorie:</span>
                {
                  subCategories?.find(
                    (cat) => cat.sub_category_id === product.subCategory_id,
                  )?.name
                }
              </div>
            )}
            <div className="catalogueItemStock">
              <span>Stock:</span> {product.stock}
            </div>
          </div>
          <Modal
            modalTitle="Modifier le produit"
            modalContent={
              <ModalContent
                setUpdatedProduct={setUpdatedProduct}
                updatedProduct={updatedProduct}
                categories={categories || []}
                subCategories={subCategories || []}
              />
            }
            modalButton={({ onClick }) => (
              <Button
                icon={<FiEdit2 />}
                onClick={onClick}
                style="square"
                className="modalButton"
                width={30}
                height={30}
              />
            )}
            modalFooterButton={
              <ModalFooterButton
                onClickWithParam={updateOrAddProduct}
                paramValue={product.product_id}
                onDelete={deleteProduct}
              />
            }
            modalFooterClass="buttonLogout"
            className="modalEditProduct"
          />
        </>
      )}
    </div>
  );
};

export default ProductCard;
