import "./OrganizationInfos.scss";

import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { BarLoader } from "react-spinners";

import Button from "../../../components/Utils/Button/Button";
import DynamicTable from "../../../components/Utils/DynamicTable/DynamicTable";
import Modal from "../../../components/Utils/Modal/Modal";
import { StyledInputFile } from "../../../components/Utils/StyledInput/StyledInput";
import useLoadingStore from "../../../stores/LoadingStore";
import useUserStore from "../../../stores/UserStore";
import OrganizationType from "../../../types/Organization";
import appFetch from "../../../Utils/Services/Fetch/appFetch";
import ModalContent, { ModalFooterButton } from "./ModalContent/ModalContent";

const OrganizationInfos = () => {
  const [organization, setOrganization] = useState<OrganizationType>();
  const [logoFile, setLogoFile] = useState<File | null>(null);
  const [backgroundFile, setBackgroundFile] = useState<File | null>(null);

  const { loadingStates, setLoading } = useLoadingStore();

  const { setIsVatSubject } = useUserStore();

  useEffect(() => {
    const fetchData = async () => {
      const orgData = await appFetch(`/api/organization/getOrganization`, {
        method: "GET",
      });
      if (orgData && orgData.organization) {
        setOrganization(orgData.organization);
        setIsVatSubject(orgData.organization.is_vat_subject);
      }
    };

    fetchData();
  }, []);

  if (!organization) {
    return <BarLoader color="#72d6e3" className="loader" width={200} />;
  }

  const updateImage = async (type: "background_image" | "logo_image") => {
    setLoading(type, true);

    let file;
    if (type === "logo_image") {
      file = logoFile;
    } else if (type === "background_image") {
      file = backgroundFile;
    }

    if (!file) {
      toast.error("Veuillez choisir un fichier");
      setLoading(type, false);
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileType", type);

    try {
      const data = await appFetch(`/api/organization/updateOrganizationFiles`, {
        method: "POST",
        body: formData,
      });

      if (data) {
        // Mettre à jour l'état de l'organisation avec les nouvelles données d'image
        setOrganization({
          ...organization,
          [type]: data[type],
        });

        // Réinitialiser les états de fichier si nécessaire
        if (type === "logo_image") {
          setLogoFile(null);
        } else if (type === "background_image") {
          setBackgroundFile(null);
        }

        toast.success(data.message);
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour de l'image:", error);
      toast.error("Une erreur est survenue lors de la mise à jour de l'image");
    }

    setLoading(type, false);
  };

  const handleCustomerPortal = async () => {
    const sessionData = await appFetch("/api/stripe/customerPortal", {
      method: "POST",
    });

    if (!sessionData) {
      toast.error(
        "Une erreur est survenue lors de la récupération du portail client",
      );
      return;
    }

    if (sessionData) {
      console.log(sessionData.url);
      window.open(sessionData.url, "_blank");
    }
  };

  const userColumns = [
    {
      title: "Nom",
      dataIndex: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
  ];

  return (
    <div className="organizationInfo">
      <div className="organizationInfoHeader">
        <div className="organizationInfosDiv">
          <h2>{organization.organization_name}</h2>
          <div className="organizationInfoRow">
            <span>Adresse:&nbsp; </span> <span>{organization.address}</span>
          </div>
          <div className="organizationInfoRow">
            <span>Email:&nbsp;</span> <span>{organization.email}</span>
          </div>
          <div className="organizationInfoRow">
            <span>Téléphone:&nbsp;</span> <span>{organization.phone}</span>
          </div>
          <div className="organizationInfoRow">
            <span>Numéro de TVA:&nbsp;</span>{" "}
            <span>{organization.vat_number}</span>
          </div>
          <div className="organizationInfoRow">
            <span>Compte Bancaire:&nbsp;</span>{" "}
            <span>{organization.bank_account}</span>
          </div>
          <div className="organizationInfoRow">
            <span>Abonnement:&nbsp;</span> <span>{organization.plan?.nom}</span>
          </div>
          <div className="organizationInfoRow">
            <span>Assujetissement à la TVA:&nbsp;</span>{" "}
            <span>{organization.is_vat_subject ? "Oui" : "Non"}</span>
          </div>
          {/* <Button
            label="Modifier mon abonnement"
            onClick={() => navigate("/payment/modify")}
            className="updateSubscriptionButton"
          /> */}
          <Button
            label="Modifier mon abonnement"
            onClick={handleCustomerPortal}
            className="updateSubscriptionButton"
          />

          <Modal
            modalTitle="Modifier l'organisation"
            modalContent={<ModalContent />}
            modalButton={({ onClick }) => (
              <Button
                label="Quitter l'organisation"
                onClick={onClick}
                className="leaveOrganizationButton"
              />
            )}
            modalFooterButton={<ModalFooterButton />}
          />
        </div>
        <div className="organizationLogos">
          <StyledInputFile
            label="Logo"
            value={organization.logo_image}
            setValue={(file) => setLogoFile(file)}
          />
          <Button
            onClick={() => updateImage("logo_image")}
            label="Enregistrer"
            isLoading={loadingStates.logo_image}
          />
        </div>
        <div className="organizationLogos">
          <StyledInputFile
            label="Background"
            value={organization.background_image}
            setValue={(file) => setBackgroundFile(file)}
          />
          <Button
            onClick={() => updateImage("background_image")}
            label="Enregistrer"
            isLoading={loadingStates.background_image}
          />
        </div>
      </div>

      <h3>Utilisateurs de l&lsquo;organisation:</h3>
      <DynamicTable
        data={organization.users ?? []}
        columns={userColumns}
        pagined
        maxRow={10}
      />
    </div>
  );
};

export default OrganizationInfos;
