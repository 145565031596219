import { FiTrash2 } from "react-icons/fi";

import Button from "../Utils/Button/Button";
import Modal from "../Utils/Modal/Modal";

interface DeleteButtonProps {
  className?: string;
  onClick?: () => void;
  isLoading?: boolean;
  width?: number;
  height?: number;
}

const DeleteButton = ({
  className,
  onClick,
  isLoading,
  width = 40,
  height = 40,
}: DeleteButtonProps) => {
  return (
    <Modal
      modalTitle="Etes vous sûr de vouloir supprimer?"
      modalContent={<div className="modalContent"></div>}
      modalButton={({ onClick }) => (
        <Button
          className="deleteButton"
          onClick={onClick}
          icon={<FiTrash2 />}
          style="square"
          width={width}
          height={height}
        />
      )}
      modalFooterButton={
        <Button
          className="deleteButton"
          onClick={onClick}
          label="Supprimer"
          isLoading={isLoading}
        />
      }
      modalFooterClass="buttonLogout"
      className={`${className} deleteButton`}
    />
  );
};

export default DeleteButton;
