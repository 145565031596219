import { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import Card from "../../../../components/Card/Card";
import StyledDropdown from "../../../../components/Utils/StyledDropdown/StyledDropdown";
import useCatalogueStore from "../../../../stores/CatalogueStore";
import ProductType from "../../../../types/Product";
import appFetch from "../../../../Utils/Services/Fetch/appFetch";
import { formatDate } from "../RevenuesAndExpensesOverTimeChart/RevenuesAndExpensesOverTimeChart";
import "./ProductRentalStats.scss";

const ProductRentalStats = () => {
  const [selectedProduct, setSelectedProduct] = useState<
    ProductType | undefined
  >(undefined);
  const { products, fetchCatalogue } = useCatalogueStore();
  const [productRentals, setProductRentals] = useState<any[]>([]);

  useEffect(() => {
    fetchCatalogue("Produits");
  }, []);

  useEffect(() => {
    if (!selectedProduct) return;

    const fetchProductRentals = async () => {
      const data = await appFetch(
        "/api/accounting/dashboard/productRentalStats",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            product_id: selectedProduct.product_id,
          }),
        },
      );
      if (data) {
        setProductRentals(data.invoices);
      }
    };

    fetchProductRentals();
  }, [selectedProduct]);

  const chartData = {
    labels: productRentals.map((rental) => formatDate(rental.issue_date)),
    datasets: [
      {
        label: "Quantitée Louée",
        data: productRentals.map((rental) => rental.quantity),
        borderColor: "rgba(54, 162, 235, 1)",
        backgroundColor: "rgba(54, 162, 235, 0.5)",
        fill: false,
      },
      {
        label: "Prix Unité",
        data: productRentals.map((rental) => rental.price_at_time_of_quote),
        borderColor: "rgba(255, 99, 132, 1)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        fill: false,
      },
    ],
  };

  return (
    <Card className="productRentalStats">
      <StyledDropdown
        label="Produit"
        value={selectedProduct}
        setValue={setSelectedProduct}
        itemList={products}
        itemLabelKey="name"
        itemIdKey="product_id"
        placeholder="Select a product"
      />
      {productRentals.length > 0 && (
        <>
          <Line data={chartData} />
          {/* display the total amount earned by this product */}
          <div className="totalAmount">
            <h3>
              Montant Total: €
              {productRentals.reduce(
                (acc, rental) =>
                  acc + rental.quantity * rental.price_at_time_of_quote,
                0,
              )}
            </h3>
          </div>
        </>
      )}
    </Card>
  );
};

export default ProductRentalStats;
