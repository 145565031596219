import "./CatalogueList.scss";

import React, { useEffect, useState } from "react";

import Spinner from "../../../components/Utils/Spinner/Spinner";
import { StyledInputText } from "../../../components/Utils/StyledInput/StyledInput";
import useCatalogueStore, { CallType } from "../../../stores/CatalogueStore";
import useLoadingStore from "../../../stores/LoadingStore";
import { CategoryType, SubCategoryType } from "../../../types/Category";
import ProductType from "../../../types/Product";
import ServiceType from "../../../types/Service";
import appFetch from "../../../Utils/Services/Fetch/appFetch";
import CategoryFilter from "./CategoryFilter/CategoryFilter";
import ProductCard from "./ProductCard/ProductCard";
import ServiceCard from "./ServiceCard/ServiceCard";

interface CatalogueListProps {
  type: CallType;
}

export type CatalogueItemType = ProductType | ServiceType;

const isProductType = (item: CatalogueItemType): item is ProductType => {
  return (item as ProductType).product_id !== undefined;
};

const CatalogueList: React.FC<CatalogueListProps> = ({ type }) => {
  const [catalogue, setCatalogue] = useState<CatalogueItemType[]>([]);
  const { products, services, fetchCatalogue } = useCatalogueStore();
  const [categories, setCategories] = useState<CategoryType[]>([]);
  const [subCategories, setSubCategories] = useState<SubCategoryType[]>([]);

  const [selectedCategory, setSelectedCategory] = useState<
    CategoryType | undefined
  >(undefined);
  const [selectedSubCategory, setSelectedSubCategory] = useState<
    SubCategoryType | undefined
  >(undefined);

  const [search, setSearch] = useState("");
  const { loadingStates } = useLoadingStore();
  const [categorySearch, setCategorySearch] = useState("");
  const [subCategorySearch, setSubCategorySearch] = useState("");

  useEffect(() => {
    fetchCatalogue(type);
  }, [type]);

  useEffect(() => {
    const fetchData = async () => {
      const emptyItem: CatalogueItemType =
        type === "Produits"
          ? {
              category_id: 0,
              created_at: "",
              image: "",
              name: "",
              price: "",
              product_id: -1, // id fictif
              stock: 0,
              subCategory_id: null,
              updated_at: "",
              tva_rate: 0, // Add missing properties
              discount: 0,
            }
          : {
              service_id: -1, // id fictif
              name: "",
              description: "",
              price: "",
              duration: "",
              created_at: "",
              updated_at: "",
              tva_rate: 0, // Add missing properties
              discount: 0,
            };

      // Ajout de l'élément vide au début de la liste
      setCatalogue([emptyItem, ...(type === "Produits" ? products : services)]);
      setSearch("");
      const categoryData = await appFetch(`/api/category/getCategoryList`);
      if (categoryData) {
        setCategories(categoryData.categorys);
        setSubCategories(categoryData.subCategories);
      }
    };

    fetchData();
  }, [products, services]);

  let finalCatalogue: CatalogueItemType[] = [];
  if (catalogue.length > 0) {
    const emptyItem = catalogue[0];
    const itemsToFilter = catalogue.slice(1); // Exclure l'élément vide du filtrage

    const filteredCatalogue = itemsToFilter.filter((item) => {
      const matchesSearch = item.name
        ?.toLowerCase()
        .includes(search.toLowerCase());
      const matchesCategory = selectedCategory
        ? isProductType(item) &&
          (item.category_id === selectedCategory.category_id ||
            item.subCategory_id === selectedCategory.category_id)
        : true;
      const matchesSubCategory = selectedSubCategory
        ? isProductType(item) &&
          item.subCategory_id === selectedSubCategory.sub_category_id
        : true;
      return matchesSearch && matchesCategory && matchesSubCategory;
    });

    finalCatalogue = [emptyItem, ...filteredCatalogue]; // Combinez l'élément vide avec les éléments filtrés
  }

  // reset the selected category and subcategory if we change the type
  useEffect(() => {
    setSelectedCategory(undefined);
    setSelectedSubCategory(undefined);
  }, [type]);

  return (
    <div className="catalogueListPage">
      <StyledInputText
        label="Rechercher"
        value={search}
        setValue={setSearch}
        className="searchInput"
      />
      {loadingStates.getCatalogue ? (
        <Spinner />
      ) : (
        <div className="catalogue">
          {type === "Produits" && (
            <CategoryFilter
              categories={categories}
              subCategories={subCategories}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              selectedSubCategory={selectedSubCategory}
              setSelectedSubCategory={setSelectedSubCategory}
              categorySearch={categorySearch}
              setCategorySearch={setCategorySearch}
              subCategorySearch={subCategorySearch}
              setSubCategorySearch={setSubCategorySearch}
            />
          )}

          <div className="catalogueList">
            {finalCatalogue.map((item) => {
              if (isProductType(item)) {
                // Rendu pour les produits
                return (
                  <ProductCard
                    product={item}
                    key={item.product_id}
                    setCatalogue={setCatalogue}
                    categories={categories}
                    subCategories={subCategories}
                  />
                );
              } else {
                // Rendu pour les services
                return (
                  <ServiceCard
                    service={item}
                    key={item.service_id}
                    setCatalogue={setCatalogue}
                  />
                );
              }
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default CatalogueList;
