import {
  endOfDay,
  endOfMonth,
  endOfYear,
  startOfDay,
  startOfMonth,
  startOfYear,
  subDays,
} from "date-fns";
import { useEffect, useState } from "react";
import Calendar from "../Utils/Calendar/Calendar";
import StyledDropdown from "../Utils/StyledDropdown/StyledDropdown";
import "./DateFilter.scss";

// This interface represents the props needed by DateFilter
interface DateFilterProps {
  startDate: Date | null;
  endDate: Date | null;
  setDateRange: (update: [Date, Date]) => void;
}

// This component offers quick shortcuts for date ranges and a custom range selection
const DateFilter = ({ startDate, endDate, setDateRange }: DateFilterProps) => {
  // Local states to handle custom start/end dates
  const [customStart, setCustomStart] = useState<Date | null>(startDate);
  const [customEnd, setCustomEnd] = useState<Date | null>(endDate);
  const [showCustom, setShowCustom] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<string>("30_JOURS");

  // Set the default date range to the last 30 days
  useEffect(() => {
    handleShortcut("30_JOURS");
  }, []);

  const dateOptions = [
    { label: "Aujourd'hui", value: "AUJOURD'HUI" },
    { label: "Hier", value: "HIER" },
    { label: "Les 7 derniers jours", value: "7_JOURS" },
    { label: "Les 30 derniers jours", value: "30_JOURS" },
    { label: "Ce mois-ci", value: "CE_MOIS_CI" },
    { label: "Le mois dernier", value: "MOIS_DERNIER" },
    { label: "Cette année", value: "CETTE_ANNEE" },
    { label: "L'année dernière", value: "ANNEE_DERNIERE" },
    { label: "Personnalisé", value: "CUSTOM" },
  ];

  // This function handles shortcut selection
  const handleShortcut = (type: string) => {
    const today = new Date();
    let rangeStart: Date = today;
    let rangeEnd: Date = today;

    switch (type) {
      case "AUJOURD'HUI":
        // Range is just today
        rangeStart = startOfDay(today);
        rangeEnd = endOfDay(today);
        break;
      case "HIER":
        // Range is yesterday
        rangeStart = startOfDay(subDays(today, 1));
        rangeEnd = endOfDay(subDays(today, 1));
        break;
      case "7_JOURS":
        // Range is last 7 days (including today)
        rangeStart = startOfDay(subDays(today, 6));
        rangeEnd = endOfDay(today);
        break;
      case "30_JOURS":
        // Range is last 30 days (including today)
        rangeStart = startOfDay(subDays(today, 29));
        rangeEnd = endOfDay(today);
        break;
      case "CE_MOIS_CI":
        // Range is the current month
        rangeStart = startOfMonth(today);
        rangeEnd = endOfMonth(today);
        break;
      case "MOIS_DERNIER":
        // Range is the previous month
        const firstDayOfCurrentMonth = startOfMonth(today);
        const lastDayOfPreviousMonth = subDays(firstDayOfCurrentMonth, 1);
        rangeStart = startOfMonth(lastDayOfPreviousMonth);
        rangeEnd = endOfMonth(lastDayOfPreviousMonth);
        break;
      case "CETTE_ANNEE":
        // Range is the current year
        rangeStart = startOfYear(today);
        rangeEnd = endOfYear(today);
        break;
      case "ANNEE_DERNIERE":
        // Range is the previous year
        const firstDayOfCurrentYear = startOfYear(today);
        const lastDayOfPreviousYear = subDays(firstDayOfCurrentYear, 1);
        rangeStart = startOfYear(lastDayOfPreviousYear);
        rangeEnd = endOfYear(lastDayOfPreviousYear);
        break;
      default:
        // If no match, we show the custom selection
        setShowCustom(true);
        setSelectedOption("CUSTOM");
        return;
    }

    // Update the global date range
    setDateRange([rangeStart, rangeEnd]);
    // Hide the custom form if it was displayed
    setShowCustom(false);
    setSelectedOption(type);
  };

  return (
    <div className="dateFilterContainer">
      {/* Dropdown for quick date range shortcuts */}
      <StyledDropdown
        value={dateOptions.find((option) => option.value === selectedOption)}
        setValue={(option) => {
          if (option) {
            handleShortcut(option.value);
          }
        }}
        itemList={dateOptions}
        label=""
        itemLabelKey="label"
        itemIdKey="value"
        placeholder="Période"
        width="225px"
      />
      {/* Show Calendar component if custom date range is selected */}
      {showCustom && (
        <Calendar
          startDate={customStart}
          endDate={customEnd}
          setDateRange={(update) => {
            setCustomStart(update[0]);
            setCustomEnd(update[1]);
            setDateRange(update);
          }}
        />
      )}
    </div>
  );
};

export default DateFilter;
