import "./ExpensesVsRevenuesChart.scss";

import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import { fr } from "date-fns/locale/fr";
import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { registerLocale } from "react-datepicker";

import DateFilter from "../../../../components/DateFilter/DateFilter";
import Spinner from "../../../../components/Utils/Spinner/Spinner";
import useLoadingStore from "../../../../stores/LoadingStore";
import appFetch from "../../../../Utils/Services/Fetch/appFetch";
registerLocale("fr", fr);
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const ExpensesVsRevenuesChart = () => {
  const startOfMonth = new Date();
  startOfMonth.setDate(1);

  const endOfMonth = new Date();
  endOfMonth.setMonth(endOfMonth.getMonth() + 1, 0);

  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    startOfMonth,
    endOfMonth,
  ]);
  const [startDate, endDate] = dateRange;
  const [expenseAmount, setExpenseAmount] = useState(0);
  const [revenueAmount, setRevenueAmount] = useState(0);
  const { loadingStates, setLoading } = useLoadingStore();

  useEffect(() => {
    const fetchData = async () => {
      setLoading("ExpensesVsRevenues", true);

      const dashboardData = await appFetch(
        "/api/accounting/dashboard/expensesVsRevenues",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            startDate: startDate?.toISOString(),
            endDate: endDate?.toISOString(),
          }),
        },
      );
      if (dashboardData) {
        setExpenseAmount(dashboardData.expenseAmount);
        setRevenueAmount(dashboardData.revenueAmount);
      }
      setLoading("ExpensesVsRevenues", false);
    };

    fetchData();
  }, [dateRange]);

  const chartData = {
    labels: ["Dépenses", "Revenus"],
    datasets: [
      {
        label: "Revenus vs Dépenses",
        data: [expenseAmount, revenueAmount],
        backgroundColor: [
          "rgba(255, 99, 132, 0.5)", // Couleur pour les dépenses
          "rgba(54, 162, 235, 0.5)", // Couleur pour les revenus
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)", // Couleur de bordure pour les dépenses
          "rgba(54, 162, 235, 1)", // Couleur de bordure pour les revenus
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className="expensesVsRevenuesChart">
      <DateFilter
        startDate={startDate}
        endDate={endDate}
        setDateRange={setDateRange}
      />
      {loadingStates.ExpensesVsRevenues ? (
        <Spinner />
      ) : (
        <Bar data={chartData} />
      )}
    </div>
  );
};

export default ExpensesVsRevenuesChart;
