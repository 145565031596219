import "./QuoteList.scss";

import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { BiDownload } from "react-icons/bi";
import { FaFileInvoiceDollar, FaHourglassHalf } from "react-icons/fa";
import { FiCheck, FiEdit2, FiXCircle } from "react-icons/fi";

import DeleteButton from "../../../components/DeleteButton/DeleteButton";
import Button from "../../../components/Utils/Button/Button";
import DynamicTable from "../../../components/Utils/DynamicTable/DynamicTable";
import Spinner from "../../../components/Utils/Spinner/Spinner";
import { StyledInputText } from "../../../components/Utils/StyledInput/StyledInput";
import StyledSwitch from "../../../components/Utils/StyledSwitch/StyledSwitch";
import useLoadingStore from "../../../stores/LoadingStore";
import QuoteType from "../../../types/Quote";
import appFetch from "../../../Utils/Services/Fetch/appFetch";

const QuoteList: React.FC<{
  onEdit: (quote: QuoteType) => void;
  onEditPage?: boolean;
}> = ({ onEdit, onEditPage }) => {
  const [quotes, setQuotes] = useState<QuoteType[]>();
  const [search, setSearch] = useState("");
  const [showFinished, setShowFinished] = useState(
    localStorage.getItem("showFinished") === "true",
  );
  const { loadingStates, setLoading } = useLoadingStore();
  const [filteredQuotes, setFilteredQuotes] = useState<QuoteType[]>([]);

  // sort by id by default
  const [sortState, setSortState] = useState<{
    column: string;
    direction: "asc" | "desc";
  } | null>(null);

  const fetchData = async () => {
    setLoading("getQuotes", true);
    const quoteData = await appFetch("/api/quote/getQuoteList");

    if (quoteData) {
      setQuotes(quoteData.quotes);
    }
    setLoading("getQuotes", false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const downloadPDF = async (quote: QuoteType) => {
    setLoading(`downloadPDF${quote.quote_id}`, true);

    const response = await appFetch(
      `/api/quote/getQuotePdf?quote_id=${quote.quote_id}`,
    );

    if (response && response instanceof Blob) {
      const filename = `DEVIS-${quote.formatted_quote_id}.pdf`;

      const blobURL = URL.createObjectURL(response);
      const link = document.createElement("a");
      link.href = blobURL;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      setLoading(`downloadPDF${quote.quote_id}`, false);
      toast.error(response.message);
    }
    setLoading(`downloadPDF${quote.quote_id}`, false);
  };

  const validateQuote = async (quoteId: number) => {
    setLoading(`validateQuote${quoteId}`, true);
    const response = await appFetch(`/api/quote/validateQuote`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ quote_id: quoteId }),
    });

    if (response && response.error) {
      toast.error(response.message);
    } else if (response && response.message) {
      toast.success(response.message);
      const quoteData = await appFetch("/api/quote/getQuoteList");
      if (quoteData) {
        setQuotes(quoteData.quotes);
      }
    }
    setLoading(`validateQuote${quoteId}`, false);
  };

  const deleteQuote = async (quoteId: number) => {
    setLoading(`deleteQuote${quoteId}`, true);
    const response = await appFetch(`/api/quote/deleteQuote`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ quote_id: quoteId }),
    });

    if (response && response.error) {
      toast.error(response.message);
    } else if (response && response.message) {
      document.getElementById("cancelModalButton")?.click();
      toast.success(response.message);
      const quoteData = await appFetch("/api/quote/getQuoteList");
      if (quoteData) {
        setQuotes(quoteData.quotes);
      }
    }
    setLoading(`deleteQuote${quoteId}`, false);
  };

  const createInvoice = async (quoteId: number) => {
    setLoading(`createInvoice${quoteId}`, true);
    const response = await appFetch(
      `/api/invoice/createInvoice?quote_id=${quoteId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      },
    );

    if (response && response.error) {
      toast.error(response.message);
    } else if (response && response.message) {
      toast.success(response.message);
      const quoteData = await appFetch("/api/quote/getQuoteList");
      if (quoteData) {
        setQuotes(quoteData.quotes);
      }
    }
    setLoading(`createInvoice${quoteId}`, false);
  };

  const handleSort = (column: keyof QuoteType | "client.client_name") => {
    const direction = sortState?.direction === "asc" ? "desc" : "asc";
    setSortState({ column, direction });
    setQuotes((prevQuotes) => {
      if (!prevQuotes) return prevQuotes;
      return prevQuotes.slice().sort((a, b) => {
        let aValue: any;
        let bValue: any;
        if (column === "client.client_name") {
          aValue = a.client?.client_name;
          bValue = b.client?.client_name;
        } else if (column === "discount") {
          aValue = Number(a.discount);
          bValue = Number(b.discount);
        } else if (column === "total_price") {
          aValue = Number(a.total_price);
          bValue = Number(b.total_price);
        } else {
          aValue = a[column];
          bValue = b[column];
        }
        if (aValue == null || bValue == null) return 0;
        if (aValue < bValue) return direction === "asc" ? -1 : 1;
        if (aValue > bValue) return direction === "asc" ? 1 : -1;
        return 0;
      });
    });
  };

  let columns = [
    {
      title: "ID",
      dataIndex: "formatted_quote_id",
      sortable: true,
      onSort: () => handleSort("formatted_quote_id"),
      sortableClass:
        sortState?.column === "formatted_quote_id"
          ? sortState.direction
          : undefined,
    },
    {
      title: "Client",
      dataIndex: "client_name",
      render: (_client_name: string, quote: QuoteType) =>
        quote.client?.client_name ?? "",
      sortable: true,
      onSort: () => handleSort("client.client_name"),
      sortableClass:
        sortState?.column === "client.client_name"
          ? sortState.direction
          : undefined,
    },
    {
      title: "Date",
      dataIndex: "rent_date",
      render: (rent_date: string) =>
        new Date(rent_date).toLocaleDateString("fr-FR", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
        }),
      sortable: true,
      onSort: () => handleSort("rent_date"),
      sortableClass:
        sortState?.column === "rent_date" ? sortState.direction : undefined,
    },
    {
      title: "Date de fin",
      dataIndex: "reserved_until",
      render: (reserved_until: string) =>
        new Date(reserved_until).toLocaleDateString("fr-FR", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
        }),
      sortable: true,
      onSort: () => handleSort("reserved_until"),
      sortableClass:
        sortState?.column === "reserved_until"
          ? sortState.direction
          : undefined,
    },
    {
      title: "Réduction (€)",
      dataIndex: "discount",
      render: (discount: string) => `${discount ?? 0} €`,
      sortable: true,
      onSort: () => handleSort("discount"),
      sortableClass:
        sortState?.column === "discount" ? sortState.direction : undefined,
    },
    {
      title: "Prix total",
      dataIndex: "total_price",
      render: (total_price: string) => `${total_price} €`,
      sortable: true,
      onSort: () => handleSort("total_price"),
      sortableClass:
        sortState?.column === "total_price" ? sortState.direction : undefined,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status: string, quote: QuoteType) => {
        const today = new Date();
        const todayStart = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate(),
        );
        const rentDate = new Date(quote.rent_date);
        const rentDateStart = new Date(
          rentDate.getFullYear(),
          rentDate.getMonth(),
          rentDate.getDate(),
        );
        const isReservedUntilDatePassed = todayStart > rentDateStart;

        return status === "Finished" ? (
          <FiCheck />
        ) : isReservedUntilDatePassed ? (
          <FiXCircle />
        ) : (
          <FaHourglassHalf />
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "actions",
      colunmWidth: "250px",
      render: (_: string, quote: QuoteType) =>
        !onEditPage ? (
          <div style={{ display: "flex", gap: "10px" }}>
            <Button
              className="downloadButton"
              onClick={() => downloadPDF(quote)}
              icon={<BiDownload />}
              isLoading={loadingStates[`downloadPDF${quote.quote_id}`]}
              style="square"
            />
            {quote.invoice_status !== "Validated" && (
              <Button
                className="editButton"
                onClick={() => onEdit(quote)}
                icon={<FiEdit2 />}
                isLoading={loadingStates[`edit${quote.quote_id}`]}
                style="square"
              />
            )}
            {quote.status === "Pending" && (
              <Button
                className="validateButton"
                onClick={() => validateQuote(quote.quote_id)}
                icon={<FiCheck />}
                isLoading={loadingStates[`validateQuote${quote.quote_id}`]}
                style="square"
              />
            )}
            {quote.invoice_status !== "Validated" && (
              <Button
                className="createInvoiceButton"
                onClick={() => createInvoice(quote.quote_id)}
                icon={<FaFileInvoiceDollar />}
                isLoading={loadingStates[`createInvoice${quote.quote_id}`]}
                style="square"
              />
            )}
            {!quote.invoice_id && (
              <DeleteButton
                onClick={() => deleteQuote(quote.quote_id)}
                isLoading={loadingStates[`deleteQuote${quote.quote_id}`]}
              />
            )}
          </div>
        ) : (
          <></>
        ),
    },
  ];

  if (onEditPage) {
    columns = columns.filter((column) => column.title !== "Actions");
  }

  const handleRowClick = (quote: QuoteType) => {
    if (onEditPage && onEdit) {
      onEdit(quote);
    }
  };

  useEffect(() => {
    // Apply both filters to quotes
    const applyFilters = quotes?.filter((quote) => {
      // Check if the quote matches the search criteria
      const matchesSearch =
        quote.event?.name?.toLowerCase().includes(search.toLowerCase()) ||
        quote.client?.client_name
          ?.toLowerCase()
          .includes(search.toLowerCase()) ||
        quote.quote_id.toString().includes(search.toLowerCase());

      // Check if the quote should be shown based on the invoice status
      const matchesFinishedFilter =
        showFinished || quote.invoice_status !== "Validated";

      return matchesSearch && matchesFinishedFilter;
    });

    setFilteredQuotes(applyFilters || []);

    // Save the filtered quotes to localStorage
    localStorage.setItem("showFinished", showFinished.toString());
  }, [quotes, search, showFinished]); // Rerun the effect if any of these dependencies change

  return (
    <div className={`quoteListPage ${onEditPage ? "onEditPage" : ""}`}>
      <div className="header">
        <StyledInputText
          label="Rechercher"
          value={search}
          setValue={setSearch}
          className="searchInput"
        />
        <StyledSwitch
          checked={showFinished}
          onChange={() => setShowFinished(!showFinished)}
          label="Afficher les devis terminés"
        />
      </div>

      <div className="tableContainer">
        {loadingStates.getQuotes ? (
          <Spinner />
        ) : (
          <DynamicTable
            data={filteredQuotes ?? []}
            columns={columns}
            onClick={onEditPage ? handleRowClick : undefined}
            pagined
            maxRow={25}
            mobile
          />
        )}
      </div>
    </div>
  );
};

export default QuoteList;
