// CatalogueStore.tsx
import { create } from "zustand";
import ProductType from "../types/Product";
import ServiceType from "../types/Service";
import appFetch from "../Utils/Services/Fetch/appFetch";
import useLoadingStore from "./LoadingStore";

export type CatalogueItemType = ProductType | ServiceType;

export type CallType = "Produits" | "Services" | "Catégories";

interface CatalogueState {
  products: ProductType[];
  setProducts: (products: ProductType[]) => void;

  services: ServiceType[];
  setServices: (services: ServiceType[]) => void;

  fetchCatalogue: (type: CallType) => Promise<void>;
  refetchCatalogue: (type: CallType) => Promise<void>;
}

const useCatalogueStore = create<CatalogueState>((set) => ({
  products: [],
  setProducts: (products) => set({ products }),

  services: [],
  setServices: (services) => set({ services }),

  fetchCatalogue: async (type: CallType) => {
    const { setLoading } = useLoadingStore.getState();
    setLoading("getCatalogue", true);
    const apiCall = type === "Produits" ? "products" : "services";
    const data = await appFetch(`/api/${apiCall}/list`);
    if (data) {
      if (type === "Produits") {
        set({ products: data });
      } else {
        set({ services: data });
      }
    }
    setLoading("getCatalogue", false);
  },

  refetchCatalogue: async (type: CallType) => {
    await useCatalogueStore.getState().fetchCatalogue(type);
  },
}));

export default useCatalogueStore;
