import "./EventsList.scss";

import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FiEdit2 } from "react-icons/fi";

import DeleteButton from "../../../components/DeleteButton/DeleteButton";
import Button from "../../../components/Utils/Button/Button";
import DynamicTable from "../../../components/Utils/DynamicTable/DynamicTable";
import Modal from "../../../components/Utils/Modal/Modal";
import Spinner from "../../../components/Utils/Spinner/Spinner";
import { StyledInputText } from "../../../components/Utils/StyledInput/StyledInput";
import useLoadingStore from "../../../stores/LoadingStore";
import EventType from "../../../types/Event";
import appFetch from "../../../Utils/Services/Fetch/appFetch";
import ModalContent, { ModalFooterButton } from "./ModalContent/ModalContent";

const EventsList = () => {
  const [events, setEvents] = useState<EventType[]>();
  const [search, setSearch] = useState("");
  const [updatedEvent, setUpdatedEvent] = useState<EventType>({
    event_id: -1,
    name: "",
    location: "",
    date: new Date(),
    start_at: "",
    end_at: "",
    link: "",
    picture: undefined,
  });
  const { loadingStates, setLoading } = useLoadingStore();

  useEffect(() => {
    const fetchData = async () => {
      await refreshData();
    };

    fetchData();
  }, []);

  const refreshData = async () => {
    setLoading("getEvents", true);

    const eventData = await appFetch("/api/events/list");
    if (eventData) {
      setEvents(eventData);
    }
    setLoading("getEvents", false);
  };

  const filteredEvents = events?.filter((event) => {
    return (
      event.name?.toLowerCase().includes(search.toLowerCase()) ||
      event.event_id.toString().includes(search.toLowerCase()) ||
      event.location?.toLowerCase().includes(search.toLowerCase())
    );
  });

  const deleteEvent = async (eventId: number) => {
    if (!eventId) {
      toast.error("ID de l'événement non spécifié.");
      return;
    }
    setLoading("deleteEvent" + eventId, true);
    const response = await appFetch(`/api/events/deleteEvent`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ event_id: eventId }),
    });

    if (response && response.error) {
      toast.error(response.message);
    } else if (response && response.message) {
      document.getElementById("cancelModalButton")?.click();
      toast.success(response.message);
      await refreshData();
    }
    setLoading("deleteEvent" + eventId, false);
  };

  const addEvent = async () => {
    setLoading("addEvent", true);
    const formData = new FormData();

    formData.append("name", updatedEvent.name || "");
    formData.append("location", updatedEvent.location || "");
    formData.append("date", updatedEvent.date.toString());
    formData.append("start_at", updatedEvent.start_at || "");
    formData.append("end_at", updatedEvent.end_at || "");
    formData.append("link", updatedEvent.link || "");
    if (updatedEvent.picture instanceof File) {
      formData.append("file", updatedEvent.picture, updatedEvent.picture.name);
    }
    try {
      const response = await appFetch(`/api/events/addEvent`, {
        method: "POST",
        body: formData,
      });
      if (response && response.error) {
        toast.error(response.message);
      } else if (response && response.message) {
        document.getElementById("cancelModalButton")?.click();
        toast.success(response.message);
        await refreshData();
      }
    } catch (error) {
      console.error("Erreur lors de l'ajout du produit:", error);
    }
    setLoading("addEvent", false);
  };

  const updateEvent = async (event_id: number) => {
    const formData = new FormData();

    formData.append("event_id", event_id.toString());
    formData.append("name", updatedEvent.name || "");
    formData.append("location", updatedEvent.location || "");
    formData.append("date", updatedEvent.date.toString());
    formData.append("start_at", updatedEvent.start_at || "");
    formData.append("end_at", updatedEvent.end_at || "");
    formData.append("link", updatedEvent.link || "");
    if (updatedEvent.picture instanceof File) {
      formData.append("file", updatedEvent.picture, updatedEvent.picture.name);
    }

    // Assurez-vous que les données nécessaires sont présentes
    if (!event_id || event_id === -1) {
      toast.error("ID de l'événement non spécifié.");
      return;
    }

    const response = await appFetch(`/api/events/updateEvent`, {
      method: "PUT", // Utilisez PUT pour une mise à jour
      body: formData,
    });

    if (response && response.error) {
      toast.error(response.message);
    } else if (response && response.message) {
      document.getElementById("cancelModalButton")?.click();
      toast.success(response.message);
      await refreshData();
    }
  };

  const handleModalUpdateOpen = (event: EventType) => {
    setUpdatedEvent({
      ...event,
      date: new Date(event.date),
    });

    // Si picture est un nom de fichier (chaîne), le charger depuis le serveur
    if (typeof event.picture === "string") {
      const fileUrl = `/api/events/getEventFile?event_id=${event.event_id}`;
      fetch(fileUrl)
        .then((response) => response.blob())
        .then((blob) => {
          // Utiliser un nom de fichier par défaut si event.picture est undefined ou vide
          const fileName = (event.picture as string) || "default-filename";
          const file = new File([blob], fileName, {
            type: blob.type,
          });
          // Mise à jour de l'état avec le fichier image
          setUpdatedEvent((prevState) => ({
            ...prevState,
            picture: file,
          }));
        });
    }
  };

  const columns = [
    {
      title: "Nom",
      dataIndex: "name",
    },
    {
      title: "Lieu",
      dataIndex: "location",
    },
    {
      title: "Date",
      dataIndex: "date",
      render: (value: Date) => new Date(value).toLocaleDateString(),
    },
    {
      title: "Début",
      dataIndex: "start_at",
    },
    {
      title: "Fin",
      dataIndex: "end_at",
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_: string, event: EventType) => (
        <div style={{ display: "flex", gap: "10px" }}>
          <Modal
            modalTitle="Modifier l'événement"
            modalContent={
              <ModalContent
                setUpdatedEvent={setUpdatedEvent}
                updatedEvent={updatedEvent}
              />
            }
            modalButton={({ onClick }) => (
              <Button
                icon={<FiEdit2 />}
                onClick={onClick}
                className="editButton"
                style="square"
              />
            )}
            modalFooterButton={
              <ModalFooterButton
                onClick={() => updateEvent(event.event_id)}
                type="Modifier"
              />
            }
            onClick={() => handleModalUpdateOpen(event)}
          />

          <DeleteButton
            onClick={() => deleteEvent(event.event_id)}
            isLoading={loadingStates["deleteEvent" + event.event_id]}
          />
        </div>
      ),
    },
  ];

  return (
    <div className={`eventListPage`}>
      <div className="eventHeader">
        <StyledInputText
          label="Rechercher"
          value={search}
          setValue={setSearch}
          className="searchInput"
        />
        <Modal
          modalTitle="Créer un événement"
          modalContent={
            <ModalContent
              setUpdatedEvent={setUpdatedEvent}
              updatedEvent={updatedEvent}
            />
          }
          modalButton={({ onClick }) => (
            <Button label="Créer" onClick={onClick} className="editButton" />
          )}
          modalFooterButton={
            <ModalFooterButton onClick={() => addEvent()} type="Créer" />
          }
          onClick={() => {
            setUpdatedEvent({
              event_id: -1,
              name: "",
              location: "",
              date: new Date(),
              start_at: "",
              end_at: "",
              link: "",
              picture: undefined,
            });
          }}
        />
      </div>
      <div className="tableContainer">
        {loadingStates.getEvents ? (
          <Spinner />
        ) : (
          <DynamicTable
            data={filteredEvents ?? []}
            columns={columns}
            pagined
            maxRow={25}
            mobile
          />
        )}
      </div>
    </div>
  );
};

export default EventsList;
