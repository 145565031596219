import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import Card from "../../../../components/Card/Card";
import DateFilter from "../../../../components/DateFilter/DateFilter";
import Spinner from "../../../../components/Utils/Spinner/Spinner";
import useLoadingStore from "../../../../stores/LoadingStore";
import appFetch from "../../../../Utils/Services/Fetch/appFetch";
import "./RevenuesAndExpensesOverTimeChart.scss";

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
);

interface ChartData {
  expenses: { date: string; amount: number }[];
  revenues: { date: string; amount: number }[];
}

// Format date to dd/mm/yyyy
export const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

const RevenuesAndExpensesOverTimeChart = () => {
  const startOfMonth = new Date();
  startOfMonth.setDate(1);

  const endOfMonth = new Date();
  endOfMonth.setMonth(endOfMonth.getMonth() + 1, 0);

  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    startOfMonth,
    endOfMonth,
  ]);
  const [startDate, endDate] = dateRange;
  const [chartData, setChartData] = useState<ChartData | null>(null);
  const { loadingStates, setLoading } = useLoadingStore();

  useEffect(() => {
    const fetchData = async () => {
      setLoading("RevenuesAndExpensesOverTime", true);

      const data = await appFetch(
        "/api/accounting/dashboard/revenuesAndExpensesOverTime",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            startDate: startDate?.toISOString(),
            endDate: endDate?.toISOString(),
          }),
        },
      );
      if (data) {
        setChartData(data);
      }
      setLoading("RevenuesAndExpensesOverTime", false);
    };

    fetchData();
  }, [dateRange]);

  // Create a unified array of dates from both expenses and revenues
  const getUniqueSortedDates = () => {
    const expenseDates = chartData?.expenses.map((item) => item.date) || [];
    const revenueDates = chartData?.revenues.map((item) => item.date) || [];
    const allDates = [...expenseDates, ...revenueDates];
    // Remove duplicate dates and sort them chronologically
    return Array.from(new Set(allDates)).sort(
      (a, b) => new Date(a).getTime() - new Date(b).getTime(),
    );
  };

  const unionDates = getUniqueSortedDates();

  const lineChartData = {
    labels: unionDates.map((date) => formatDate(date)),
    datasets: [
      {
        label: "Revenus",
        data: unionDates.map((date) => {
          // Find revenue data for the corresponding date
          const revenue = chartData?.revenues.find(
            (item) =>
              new Date(item.date).toISOString() ===
              new Date(date).toISOString(),
          );
          return revenue ? revenue.amount : null;
        }),
        borderColor: "rgba(54, 162, 235, 1)",
        backgroundColor: "rgba(54, 162, 235, 0.5)",
        fill: false,
        spanGaps: true, // Connect points even if data is missing
      },
      {
        label: "Dépenses",
        data: unionDates.map((date) => {
          // Find expense data for the corresponding date
          const expense = chartData?.expenses.find(
            (item) =>
              new Date(item.date).toISOString() ===
              new Date(date).toISOString(),
          );
          return expense ? expense.amount : null;
        }),
        borderColor: "rgba(255, 99, 132, 1)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        fill: false,
        spanGaps: true, // Connect points even if data is missing
      },
    ],
  };

  return (
    <Card className="revenuesAndExpensesOverTimeChart">
      <DateFilter
        startDate={startDate}
        endDate={endDate}
        setDateRange={setDateRange}
      />
      {loadingStates.RevenuesAndExpensesOverTime ? (
        <Spinner />
      ) : (
        <>
          <Line data={lineChartData} />
          <div className="totalAmount">
            <h3>
              Dépenses Totales: €
              {chartData?.expenses
                .reduce((acc, expense) => acc + expense.amount, 0)
                .toFixed(2)}
            </h3>
            <h3>
              Revenus Totales: €
              {chartData?.revenues
                .reduce((acc, revenue) => acc + revenue.amount, 0)
                .toFixed(2)}
            </h3>
          </div>
        </>
      )}
    </Card>
  );
};

export default RevenuesAndExpensesOverTimeChart;
