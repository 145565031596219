import "./QuoteForm.scss";

import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FaUndo } from "react-icons/fa";
import { FiArrowLeft } from "react-icons/fi";

import Button from "../../../components/Utils/Button/Button";
import StyledDropdown from "../../../components/Utils/StyledDropdown/StyledDropdown";
import {
  StyledInputDate,
  StyledInputNumber,
  StyledTextArea,
} from "../../../components/Utils/StyledInput/StyledInput";
import useLoadingStore from "../../../stores/LoadingStore";
import useQuoteStore from "../../../stores/QuoteStore";
import useUserStore from "../../../stores/UserStore";
import ClientType from "../../../types/Client";
import EventType from "../../../types/Event";
import ProductType, { SelectedProduct } from "../../../types/Product";
import { SelectedService } from "../../../types/Service";
import appFetch from "../../../Utils/Services/Fetch/appFetch";
import ProductTable from "./../Tables/ProductTable";
import ServiceTable from "./../Tables/ServiceTable";

const QuoteForm = () => {
  const { loadingStates, setLoading } = useLoadingStore();
  const { isVatSubject } = useUserStore();

  const {
    quote,
    setQuote,
    selectedProducts,
    setSelectedProducts,
    selectedServices,
    setSelectedServices,
    quoteToEdit,
    setQuoteToEdit,
    client,
    setClient,
  } = useQuoteStore();

  const [products, setProducts] = useState<ProductType[]>([]);
  const [services, setServices] = useState<SelectedService[]>([]);
  const [quoteId, setQuoteId] = useState<number | undefined>();
  const [formattedQuoteId, setFormattedQuoteId] = useState<string>("");
  const [partyList, setPartyList] = useState<EventType[]>([]);

  // to get the client list for the client select
  const [clientsList, setClientsList] = useState<ClientType[]>([]);
  // select the client for the quote

  useEffect(() => {
    const fetchData = async () => {
      const productsData = await appFetch("/api/products/list");
      if (productsData) {
        setProducts(productsData);
      }
      const servicesData = await appFetch("/api/services/list");
      if (servicesData) {
        setServices(servicesData);
      }

      const clientsData = await appFetch("/api/clients/list");
      if (clientsData && !clientsData.unauthorized) {
        setClientsList(clientsData);
      }
      const partyData = await appFetch("/api/events/list");
      if (partyData && !partyData.unauthorized) {
        setPartyList(partyData);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchQuoteDetails = async () => {
      if (quoteToEdit) {
        try {
          const quoteDetailData = await appFetch(
            `/api/quote/getQuoteDetails?quote_id=${quoteToEdit.quote_id}`,
          );
          if (
            quoteDetailData &&
            quoteDetailData.products &&
            quoteDetailData.services
          ) {
            console.log(quoteDetailData.products);
            const productsWithRowId = quoteDetailData.products.map(
              (product: SelectedProduct) => ({
                ...product,
                row_id: product.product_id || new Date().getTime(), // Utiliser product_id ou un timestamp comme fallback
              }),
            );

            // Faire de même pour les services si nécessaire
            const servicesWithRowId = quoteDetailData.services.map(
              (service: SelectedService) => ({
                ...service,
                row_id: service.service_id || new Date().getTime() * 2, // Assurez-vous que l'ID est unique
              }),
            );

            setSelectedProducts(productsWithRowId);
            setSelectedServices(servicesWithRowId);
            setRentDate(new Date(quoteDetailData.quote.rent_date));
            setReservedFrom(new Date(quoteDetailData.quote.reserved_until));
            setClient(quoteDetailData.client);
            setCoeficient(quoteDetailData.quote.coefficient);
            setQuote((prevQuote) => {
              return {
                ...prevQuote,
                reserved_until: new Date(quoteDetailData.quote.reserved_until),
                description: quoteDetailData.quote.description,
                event: quoteDetailData.event,
                coefficient: quoteDetailData.quote.coefficient,
                discount: quoteDetailData.quote.discount,
              };
            });
          }
        } catch (error) {
          console.error(
            "Erreur lors de la récupération des détails du devis :",
            error,
          );
        }
      }
    };

    fetchQuoteDetails();
  }, [quoteToEdit]);

  const addProductRow = () => {
    setSelectedProducts((prevProducts) => [
      ...prevProducts,
      {
        category_id: -1,
        created_at: "",
        image: "",
        name: "",
        price: "0",
        product_id: -1,
        stock: 0,
        subCategory_id: null,
        updated_at: "",
        quantity: 1,
        tva_rate: 0,
        discount: 0,
        row_id: new Date().getTime(),
      },
    ]);
  };

  const addServiceRow = () => {
    setSelectedServices((prevServices) => [
      ...prevServices,
      {
        service_id: -1,
        quantity: 1,
        name: "",
        description: "",
        price: "0",
        duration: "",
        row_id: new Date().getTime() * 3,
        tva_rate: 0,
        discount: 0,
        created_at: "",
        updated_at: "",
      },
    ]);
  };

  const calculateTotal = (type = "TTC") => {
    // Calcule le total HT pour les produits
    let totalProductsTTC = 0;
    let totalServicesTTC = 0;

    const totalProductsHT = selectedProducts.reduce((total, product) => {
      const tvaRate = isVatSubject ? (product.tva_rate ?? 0) : 0;
      const discount = product.discount ?? 0;
      const totalHT = parseFloat(product.price) * (product.quantity ?? 0);

      const totalHTWithCoef = totalHT * quote.coefficient;
      const totalHTWithDiscount = totalHTWithCoef - discount;

      totalProductsTTC += totalHTWithDiscount * (1 + tvaRate / 100);

      return total + totalHTWithDiscount;
    }, 0);

    // Calcule le total HT pour les services
    const totalServicesHT = selectedServices.reduce((total, service) => {
      const tvaRate = isVatSubject ? (service.tva_rate ?? 0) : 0;
      const discount = service.discount ?? 0;
      const totalHT = parseFloat(service.price) * (service.quantity ?? 0);
      const totalHTWithDiscount = totalHT - discount;
      totalServicesTTC += totalHTWithDiscount * (1 + tvaRate / 100);

      return total + totalHTWithDiscount;
    }, 0);

    // Calcule le total final HT
    const totalHT = totalProductsHT + totalServicesHT;

    // Calcule le total TVA
    const totalTTC = totalProductsTTC + totalServicesTTC;

    // Choisissez le type de total à retourner
    switch (type) {
      case "HT":
        return Math.max(totalHT, 0);
      case "TTC":
        return Math.max(totalTTC, 0);
      default:
        return Math.max(totalTTC, 0);
    }
  };

  const calculateTotalByTVA = () => {
    const tvaTotals: { [tvaRate: string]: number } = {};

    // Calculer les totaux pour les produits
    selectedProducts.forEach((product) => {
      const tvaRate = (product.tva_rate ?? 0).toString();
      const discount = product.discount ?? 0;

      const totalHT = parseFloat(product.price) * (product.quantity ?? 0);
      const totalHTWithCoef = totalHT * quote.coefficient;
      const totalHTWithDiscount = totalHTWithCoef - discount;

      const totalTVA = (totalHTWithDiscount * (product.tva_rate ?? 0)) / 100;

      if (!tvaTotals[tvaRate]) {
        tvaTotals[tvaRate] = 0;
      }

      tvaTotals[tvaRate] += totalTVA;
    });

    // Calculer les totaux pour les services
    selectedServices.forEach((service) => {
      const tvaRate = (service.tva_rate ?? 0).toString();
      const discount = service.discount ?? 0;

      const totalHT = parseFloat(service.price) * (service.quantity ?? 0);
      const totalHTWithDiscount = totalHT - discount;
      const totalTVA = (totalHTWithDiscount * (service.tva_rate ?? 0)) / 100;

      if (!tvaTotals[tvaRate]) {
        tvaTotals[tvaRate] = 0;
      }

      tvaTotals[tvaRate] += totalTVA;
    });

    return tvaTotals;
  };

  const setRentDate = (value: Date) => {
    setQuote((prevQuote) => {
      return { ...prevQuote, rent_date: value };
    });
  };

  const setReservedFrom = (value: Date) => {
    setQuote((prevQuote) => {
      return { ...prevQuote, reserved_until: value };
    });
  };

  const setCoeficient = (value: number) => {
    setQuote((prevQuote) => {
      return { ...prevQuote, coefficient: value };
    });
  };

  const createQuote = async (type: string) => {
    // Vérifie s'il y a au moins un produit ou un service sélectionné
    setLoading(type, true);
    if (selectedProducts.length === 0 && selectedServices.length === 0) {
      toast.error("Veuillez sélectionner au moins un produit ou un service.");
      setLoading(type, false);
      return;
    }

    // Vérifie si un utilisateur a été sélectionné
    if (!client) {
      toast.error("Veuillez sélectionner un client.");
      setLoading(type, false);
      return;
    }

    // Vérifie si une date de location et une date de réservation ont été sélectionnées
    if (!quote.rent_date || !quote.reserved_until || !quote.reserved_until) {
      toast.error(
        "Veuillez sélectionner les dates de location, de réservation et de fin de réservation.",
      );
      setLoading(type, false);
      return;
    }

    // Prépare les données pour la requête
    const data = {
      client_id: client.client_id,
      products: selectedProducts
        .filter((product) => product.product_id !== -1)
        .map((product) => ({
          product_id: product.product_id,
          quantity: product.quantity,
          price: product.price,
          tva_rate: isVatSubject ? product.tva_rate : 0,
          discount: product.discount,
        })),
      services: selectedServices
        .filter((service) => service.service_id !== -1)
        .map((service) => ({
          service_id: service.service_id,
          quantity: service.quantity,
          price: service.price,
          tva_rate: isVatSubject ? service.tva_rate : 0,
          discount: service.discount,
        })),
      rent_date: quote.rent_date,
      reserved_from: quote.rent_date,
      coefficient: quote.coefficient || 1,
      reserved_until: quote.reserved_until,
      description: quote.description,
      event_id: quote.event?.event_id,
      quote_id: quoteToEdit?.quote_id,
    };

    // Effectue la requête HTTP pour créer le devis
    try {
      const method = type === "createQuote" ? "POST" : "PUT";

      const response = await appFetch(`/api/quote/${type}`, {
        method: method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      toast.success(response.message);
      setQuoteId(response.quote.quote_id);
      setFormattedQuoteId(response.quote.formatted_quote_id);
      handleReset();
    } catch (error) {
      console.error("Erreur lors de la création du devis :", error);
    }
    setLoading(type, false);
  };

  const downloadPDF = async () => {
    setLoading("downloadPDF", true);
    const pdfBlob = await appFetch(
      `/api/quote/getQuotePdf?quote_id=${quoteId}`,
    );

    if (pdfBlob && pdfBlob instanceof Blob) {
      const blobURL = URL.createObjectURL(pdfBlob);
      const link = document.createElement("a");
      link.href = blobURL;
      link.download = `DEVIS_${formattedQuoteId}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else if (pdfBlob && pdfBlob.error) {
      toast.error(pdfBlob.message);
    }
    setLoading("downloadPDF", false);
  };

  const handleReset = () => {
    setSelectedProducts([
      {
        category_id: -1,
        created_at: "",
        image: "",
        name: "",
        price: "0",
        product_id: -1,
        stock: 0,
        subCategory_id: null,
        updated_at: "",
        quantity: 1,
        tva_rate: 0,
        discount: 0,
        row_id: new Date().getTime(),
      },
    ]);
    setSelectedServices([
      {
        service_id: -1,
        quantity: 1,
        name: "",
        description: "",
        price: "0",
        duration: "",
        row_id: new Date().getTime() * 3,
        tva_rate: 0,
        discount: 0,
        created_at: "",
        updated_at: "",
      },
    ]);
    setQuote({
      quote_id: 0,
      client_id: 0,
      total_price: 0,
      status: "",
      user_decision: "",
      created_at: new Date(),
      updated_at: new Date(),
      valid_until: new Date(),
      rent_date: new Date(),
      reserved_from: new Date(),
      coefficient: 1,
      reserved_until: new Date(),
      description: "",
    });
    setClient(undefined);
  };

  function calculateCoefficient(rent_date: Date, reserved_until: Date): number {
    const diffTime = Math.abs(reserved_until.getTime() - rent_date.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    let coefficientPrice = 1;
    if (diffDays > 1) {
      coefficientPrice += 0.25 * (diffDays - 1);
    }
    return coefficientPrice;
  }

  useEffect(() => {
    // when we change the rent date or reserved until date we need to recalculate the coefficient
    if (!quoteToEdit) {
      const coefficient = calculateCoefficient(
        quote.rent_date,
        quote.reserved_until,
      );
      setQuote((prevQuote) => {
        return { ...prevQuote, coefficient: coefficient };
      });
    }
  }, [quote.rent_date, quote.reserved_until]);

  return (
    <div className="quoteFormPage">
      {quoteToEdit && (
        <Button
          onClick={() => setQuoteToEdit(null)}
          className="backBtn"
          icon={<FiArrowLeft />}
        />
      )}
      {/* Selected Products Table */}
      <ProductTable
        selectedProducts={selectedProducts}
        setSelectedProducts={setSelectedProducts}
        products={products}
        addProductRow={addProductRow}
        coefficient={quote.coefficient}
      />
      <ServiceTable
        selectedServices={selectedServices}
        setSelectedServices={setSelectedServices}
        services={services}
        addServiceRow={addServiceRow}
      />
      <table className="table totalSections">
        <tfoot>
          <tr>
            <td>TOTAL {isVatSubject && "HT"}</td>
            <td></td>
            <td></td>
            <td></td>
            {isVatSubject && (
              <>
                <td></td>
                <td></td>
              </>
            )}
            <td>{calculateTotal("HT")} €</td>
            <td></td>
          </tr>
          {isVatSubject && (
            <>
              {Object.entries(calculateTotalByTVA()).map(
                ([tvaRate, totalTVA]) => (
                  <tr key={tvaRate}>
                    <td>TVA {tvaRate}%</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>{totalTVA.toFixed(2)} €</td>
                    <td></td>
                  </tr>
                ),
              )}
              <tr>
                <td>TOTAL TTC</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>{calculateTotal("TTC").toFixed(2)} €</td>
                <td></td>
              </tr>
            </>
          )}
        </tfoot>
      </table>
      {/* client */}
      <div className="dateSelection">
        <StyledDropdown
          label="Client*"
          value={client}
          setValue={setClient}
          itemList={clientsList}
          itemLabelKey="client_name"
          itemIdKey="client_id"
        />
        <StyledInputDate
          label="Date de début*"
          value={quote.rent_date}
          setValue={setRentDate}
        />
        <StyledInputDate
          label="Date de fin*"
          value={quote.reserved_until}
          setValue={setReservedFrom}
        />
        <StyledInputNumber
          label="Coefficient Journée*"
          value={quote.coefficient ? quote.coefficient.toString() : ""}
          setValue={(value) => {
            setCoeficient(Number(value));
          }}
        />

        <StyledDropdown
          label="Événement"
          value={quote.event}
          setValue={(selectedEvent: EventType | undefined) => {
            setQuote((prevQuote) => {
              return { ...prevQuote, event: selectedEvent };
            });
          }}
          itemList={partyList}
          itemLabelKey="name"
          itemIdKey="event_id"
        />
        <StyledTextArea
          label="Description"
          value={quote.description}
          setValue={(value: string) => {
            setQuote((prevQuote) => {
              return { ...prevQuote, description: value };
            });
          }}
        />
      </div>

      <div className="buttonContainer">
        {!quoteToEdit && (
          <Button
            onClick={() => {
              createQuote("createQuote");
            }}
            className="submitBtn"
            label="Créer le devis"
            isLoading={loadingStates["createQuote"]}
          />
        )}
        {quoteToEdit && (
          <Button
            onClick={() => {
              createQuote("updateQuote");
            }}
            className="submitBtn"
            label="Modifier le devis"
            isLoading={loadingStates["updateQuote"]}
          />
        )}
        <Button
          onClick={() => {
            handleReset();
          }}
          className="squaredButton"
          icon={<FaUndo />}
        />

        {quoteId && (
          <Button
            onClick={() => {
              downloadPDF();
            }}
            className="submitBtn"
            label="Download PDF"
            isLoading={loadingStates["downloadPDF"]}
          />
        )}
      </div>
    </div>
  );
};
export default QuoteForm;
